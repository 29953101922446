import { template as template_6841aef6327f4719aca52efef3efae01 } from "@ember/template-compiler";
import { eq, notEq } from 'ember-truth-helpers';
export default template_6841aef6327f4719aca52efef3efae01(`
  <div
    role="tabpanel"
    class="pix-tabs__tabpanel"
    id="panel-{{@id}}-{{@index}}"
    tabindex={{if (eq @currentTab @index) "0" "-1"}}
    aria-labelledby="{{@id}}-{{@index}}"
    hidden={{notEq @currentTab @index}}
  >
    {{#if (eq @currentTab @index)}}
      {{yield}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
