
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("mon-pix/adapters/account-recovery-demand", function(){ return i("mon-pix/adapters/account-recovery-demand.js");});
d("mon-pix/adapters/application", function(){ return i("mon-pix/adapters/application.js");});
d("mon-pix/adapters/area", function(){ return i("mon-pix/adapters/area.js");});
d("mon-pix/adapters/assessment", function(){ return i("mon-pix/adapters/assessment.js");});
d("mon-pix/adapters/authentication-method", function(){ return i("mon-pix/adapters/authentication-method.js");});
d("mon-pix/adapters/campaign-participation-overview", function(){ return i("mon-pix/adapters/campaign-participation-overview.js");});
d("mon-pix/adapters/campaign-participation-result", function(){ return i("mon-pix/adapters/campaign-participation-result.js");});
d("mon-pix/adapters/campaign-participation", function(){ return i("mon-pix/adapters/campaign-participation.js");});
d("mon-pix/adapters/certification-candidate-subscription", function(){ return i("mon-pix/adapters/certification-candidate-subscription.js");});
d("mon-pix/adapters/certification-candidate", function(){ return i("mon-pix/adapters/certification-candidate.js");});
d("mon-pix/adapters/certification", function(){ return i("mon-pix/adapters/certification.js");});
d("mon-pix/adapters/challenge", function(){ return i("mon-pix/adapters/challenge.js");});
d("mon-pix/adapters/competence-evaluation", function(){ return i("mon-pix/adapters/competence-evaluation.js");});
d("mon-pix/adapters/dependent-user", function(){ return i("mon-pix/adapters/dependent-user.js");});
d("mon-pix/adapters/element-answer", function(){ return i("mon-pix/adapters/element-answer.js");});
d("mon-pix/adapters/email-verification-code", function(){ return i("mon-pix/adapters/email-verification-code.js");});
d("mon-pix/adapters/external-user-authentication-request", function(){ return i("mon-pix/adapters/external-user-authentication-request.js");});
d("mon-pix/adapters/external-user", function(){ return i("mon-pix/adapters/external-user.js");});
d("mon-pix/adapters/oidc-identity-provider", function(){ return i("mon-pix/adapters/oidc-identity-provider.js");});
d("mon-pix/adapters/organization-learner-identity", function(){ return i("mon-pix/adapters/organization-learner-identity.js");});
d("mon-pix/adapters/organization-learner", function(){ return i("mon-pix/adapters/organization-learner.js");});
d("mon-pix/adapters/passage", function(){ return i("mon-pix/adapters/passage.js");});
d("mon-pix/adapters/quest-result", function(){ return i("mon-pix/adapters/quest-result.js");});
d("mon-pix/adapters/reset-expired-password-demand", function(){ return i("mon-pix/adapters/reset-expired-password-demand.js");});
d("mon-pix/adapters/sco-organization-learner", function(){ return i("mon-pix/adapters/sco-organization-learner.js");});
d("mon-pix/adapters/scorecard", function(){ return i("mon-pix/adapters/scorecard.js");});
d("mon-pix/adapters/shared-profile-for-campaign", function(){ return i("mon-pix/adapters/shared-profile-for-campaign.js");});
d("mon-pix/adapters/student-information", function(){ return i("mon-pix/adapters/student-information.js");});
d("mon-pix/adapters/sup-organization-learner", function(){ return i("mon-pix/adapters/sup-organization-learner.js");});
d("mon-pix/adapters/training", function(){ return i("mon-pix/adapters/training.js");});
d("mon-pix/adapters/tutorial-evaluation", function(){ return i("mon-pix/adapters/tutorial-evaluation.js");});
d("mon-pix/adapters/tutorial", function(){ return i("mon-pix/adapters/tutorial.js");});
d("mon-pix/adapters/user-oidc-authentication-request", function(){ return i("mon-pix/adapters/user-oidc-authentication-request.js");});
d("mon-pix/adapters/user-saved-tutorial", function(){ return i("mon-pix/adapters/user-saved-tutorial.js");});
d("mon-pix/adapters/user", function(){ return i("mon-pix/adapters/user.js");});
d("mon-pix/app", function(){ return i("mon-pix/app.js");});
d("mon-pix/authenticators/anonymous", function(){ return i("mon-pix/authenticators/anonymous.js");});
d("mon-pix/authenticators/gar", function(){ return i("mon-pix/authenticators/gar.js");});
d("mon-pix/authenticators/oauth2", function(){ return i("mon-pix/authenticators/oauth2.js");});
d("mon-pix/authenticators/oidc", function(){ return i("mon-pix/authenticators/oidc.js");});
d("mon-pix/breakpoints", function(){ return i("mon-pix/breakpoints.js");});
d("mon-pix/config/environment", function(){ return i("mon-pix/config/environment.js");});
d("mon-pix/errors/application-error", function(){ return i("mon-pix/errors/application-error.js");});
d("mon-pix/errors/factories/create-application-error", function(){ return i("mon-pix/errors/factories/create-application-error.js");});
d("mon-pix/errors/json-api-error", function(){ return i("mon-pix/errors/json-api-error.js");});
d("mon-pix/formats", function(){ return i("mon-pix/formats.js");});
d("mon-pix/initializers/register-showdown-extensions", function(){ return i("mon-pix/initializers/register-showdown-extensions.js");});
d("mon-pix/initializers/responsive", function(){ return i("mon-pix/initializers/responsive.js");});
d("mon-pix/instance-initializers/session", function(){ return i("mon-pix/instance-initializers/session.js");});
d("mon-pix/languages", function(){ return i("mon-pix/languages.js");});
d("mon-pix/mixins/progression-tracker", function(){ return i("mon-pix/mixins/progression-tracker.js");});
d("mon-pix/mixins/secured-route-mixin", function(){ return i("mon-pix/mixins/secured-route-mixin.js");});
d("mon-pix/models/account-info", function(){ return i("mon-pix/models/account-info.js");});
d("mon-pix/models/account-recovery-demand", function(){ return i("mon-pix/models/account-recovery-demand.js");});
d("mon-pix/models/answer", function(){ return i("mon-pix/models/answer.js");});
d("mon-pix/models/area", function(){ return i("mon-pix/models/area.js");});
d("mon-pix/models/assessment", function(){ return i("mon-pix/models/assessment.js");});
d("mon-pix/models/authentication-method", function(){ return i("mon-pix/models/authentication-method.js");});
d("mon-pix/models/badge", function(){ return i("mon-pix/models/badge.js");});
d("mon-pix/models/campaign-participation-badge", function(){ return i("mon-pix/models/campaign-participation-badge.js");});
d("mon-pix/models/campaign-participation-overview", function(){ return i("mon-pix/models/campaign-participation-overview.js");});
d("mon-pix/models/campaign-participation-result", function(){ return i("mon-pix/models/campaign-participation-result.js");});
d("mon-pix/models/campaign-participation", function(){ return i("mon-pix/models/campaign-participation.js");});
d("mon-pix/models/campaign", function(){ return i("mon-pix/models/campaign.js");});
d("mon-pix/models/certification-candidate-subscription", function(){ return i("mon-pix/models/certification-candidate-subscription.js");});
d("mon-pix/models/certification-candidate", function(){ return i("mon-pix/models/certification-candidate.js");});
d("mon-pix/models/certification-course", function(){ return i("mon-pix/models/certification-course.js");});
d("mon-pix/models/certification", function(){ return i("mon-pix/models/certification.js");});
d("mon-pix/models/challenge", function(){ return i("mon-pix/models/challenge.js");});
d("mon-pix/models/competence-evaluation", function(){ return i("mon-pix/models/competence-evaluation.js");});
d("mon-pix/models/competence-result", function(){ return i("mon-pix/models/competence-result.js");});
d("mon-pix/models/competence", function(){ return i("mon-pix/models/competence.js");});
d("mon-pix/models/correction-response", function(){ return i("mon-pix/models/correction-response.js");});
d("mon-pix/models/correction", function(){ return i("mon-pix/models/correction.js");});
d("mon-pix/models/course", function(){ return i("mon-pix/models/course.js");});
d("mon-pix/models/dependent-user", function(){ return i("mon-pix/models/dependent-user.js");});
d("mon-pix/models/element-answer", function(){ return i("mon-pix/models/element-answer.js");});
d("mon-pix/models/email-verification-code", function(){ return i("mon-pix/models/email-verification-code.js");});
d("mon-pix/models/external-user-authentication-request", function(){ return i("mon-pix/models/external-user-authentication-request.js");});
d("mon-pix/models/external-user", function(){ return i("mon-pix/models/external-user.js");});
d("mon-pix/models/feature-toggle", function(){ return i("mon-pix/models/feature-toggle.js");});
d("mon-pix/models/feedback", function(){ return i("mon-pix/models/feedback.js");});
d("mon-pix/models/grain", function(){ return i("mon-pix/models/grain.js");});
d("mon-pix/models/is-certifiable", function(){ return i("mon-pix/models/is-certifiable.js");});
d("mon-pix/models/levelup", function(){ return i("mon-pix/models/levelup.js");});
d("mon-pix/models/module", function(){ return i("mon-pix/models/module.js");});
d("mon-pix/models/oidc-identity-provider", function(){ return i("mon-pix/models/oidc-identity-provider.js");});
d("mon-pix/models/organization-learner-identity", function(){ return i("mon-pix/models/organization-learner-identity.js");});
d("mon-pix/models/organization-learner", function(){ return i("mon-pix/models/organization-learner.js");});
d("mon-pix/models/passage", function(){ return i("mon-pix/models/passage.js");});
d("mon-pix/models/profile", function(){ return i("mon-pix/models/profile.js");});
d("mon-pix/models/progression", function(){ return i("mon-pix/models/progression.js");});
d("mon-pix/models/quest-result", function(){ return i("mon-pix/models/quest-result.js");});
d("mon-pix/models/reached-stage", function(){ return i("mon-pix/models/reached-stage.js");});
d("mon-pix/models/reset-expired-password-demand", function(){ return i("mon-pix/models/reset-expired-password-demand.js");});
d("mon-pix/models/result-competence-tree", function(){ return i("mon-pix/models/result-competence-tree.js");});
d("mon-pix/models/result-competence", function(){ return i("mon-pix/models/result-competence.js");});
d("mon-pix/models/sco-organization-learner", function(){ return i("mon-pix/models/sco-organization-learner.js");});
d("mon-pix/models/scorecard", function(){ return i("mon-pix/models/scorecard.js");});
d("mon-pix/models/shared-profile-for-campaign", function(){ return i("mon-pix/models/shared-profile-for-campaign.js");});
d("mon-pix/models/student-information", function(){ return i("mon-pix/models/student-information.js");});
d("mon-pix/models/sup-organization-learner", function(){ return i("mon-pix/models/sup-organization-learner.js");});
d("mon-pix/models/training", function(){ return i("mon-pix/models/training.js");});
d("mon-pix/models/tutorial-evaluation", function(){ return i("mon-pix/models/tutorial-evaluation.js");});
d("mon-pix/models/tutorial", function(){ return i("mon-pix/models/tutorial.js");});
d("mon-pix/models/user-oidc-authentication-request", function(){ return i("mon-pix/models/user-oidc-authentication-request.js");});
d("mon-pix/models/user-saved-tutorial", function(){ return i("mon-pix/models/user-saved-tutorial.js");});
d("mon-pix/models/user", function(){ return i("mon-pix/models/user.js");});
d("mon-pix/router", function(){ return i("mon-pix/router.js");});
d("mon-pix/serializers/application", function(){ return i("mon-pix/serializers/application.js");});
d("mon-pix/serializers/assessment", function(){ return i("mon-pix/serializers/assessment.js");});
d("mon-pix/serializers/campaign", function(){ return i("mon-pix/serializers/campaign.js");});
d("mon-pix/services/ajax-queue", function(){ return i("mon-pix/services/ajax-queue.js");});
d("mon-pix/services/ajax", function(){ return i("mon-pix/services/ajax.js");});
d("mon-pix/services/authentication", function(){ return i("mon-pix/services/authentication.js");});
d("mon-pix/services/campaign-storage", function(){ return i("mon-pix/services/campaign-storage.js");});
d("mon-pix/services/competence-evaluation", function(){ return i("mon-pix/services/competence-evaluation.js");});
d("mon-pix/services/current-domain", function(){ return i("mon-pix/services/current-domain.js");});
d("mon-pix/services/current-user", function(){ return i("mon-pix/services/current-user.js");});
d("mon-pix/services/errors", function(){ return i("mon-pix/services/errors.js");});
d("mon-pix/services/feature-toggles", function(){ return i("mon-pix/services/feature-toggles.js");});
d("mon-pix/services/file-saver", function(){ return i("mon-pix/services/file-saver.js");});
d("mon-pix/services/focused-certification-challenge-warning-manager", function(){ return i("mon-pix/services/focused-certification-challenge-warning-manager.js");});
d("mon-pix/services/locale", function(){ return i("mon-pix/services/locale.js");});
d("mon-pix/services/location", function(){ return i("mon-pix/services/location.js");});
d("mon-pix/services/modulix-auto-scroll", function(){ return i("mon-pix/services/modulix-auto-scroll.js");});
d("mon-pix/services/modulix-preview-mode", function(){ return i("mon-pix/services/modulix-preview-mode.js");});
d("mon-pix/services/oidc-identity-providers", function(){ return i("mon-pix/services/oidc-identity-providers.js");});
d("mon-pix/services/pix-companion", function(){ return i("mon-pix/services/pix-companion.js");});
d("mon-pix/services/raven", function(){ return i("mon-pix/services/raven.js");});
d("mon-pix/services/request-manager-handlers/app-info-handler", function(){ return i("mon-pix/services/request-manager-handlers/app-info-handler.js");});
d("mon-pix/services/request-manager-handlers/auth-handler", function(){ return i("mon-pix/services/request-manager-handlers/auth-handler.js");});
d("mon-pix/services/request-manager-handlers/json-handler", function(){ return i("mon-pix/services/request-manager-handlers/json-handler.js");});
d("mon-pix/services/request-manager-handlers/locale-handler", function(){ return i("mon-pix/services/request-manager-handlers/locale-handler.js");});
d("mon-pix/services/request-manager", function(){ return i("mon-pix/services/request-manager.js");});
d("mon-pix/services/session", function(){ return i("mon-pix/services/session.js");});
d("mon-pix/services/splash", function(){ return i("mon-pix/services/splash.js");});
d("mon-pix/services/store", function(){ return i("mon-pix/services/store.js");});
d("mon-pix/services/tab-manager", function(){ return i("mon-pix/services/tab-manager.js");});
d("mon-pix/services/url", function(){ return i("mon-pix/services/url.js");});
d("mon-pix/static-data/feedback-panel-issue-labels", function(){ return i("mon-pix/static-data/feedback-panel-issue-labels.js");});
d("mon-pix/transforms/array", function(){ return i("mon-pix/transforms/array.js");});
d("mon-pix/transforms/boolean", function(){ return i("mon-pix/transforms/boolean.js");});
d("mon-pix/transforms/date-only", function(){ return i("mon-pix/transforms/date-only.js");});
d("mon-pix/transforms/date", function(){ return i("mon-pix/transforms/date.js");});
d("mon-pix/transforms/duration-extended", function(){ return i("mon-pix/transforms/duration-extended.js");});
d("mon-pix/transforms/number", function(){ return i("mon-pix/transforms/number.js");});
d("mon-pix/transforms/string", function(){ return i("mon-pix/transforms/string.js");});
d("mon-pix/utils/answers-as-object", function(){ return i("mon-pix/utils/answers-as-object.js");});
d("mon-pix/utils/button-status-types", function(){ return i("mon-pix/utils/button-status-types.js");});
d("mon-pix/utils/color-gradient", function(){ return i("mon-pix/utils/color-gradient.js");});
d("mon-pix/utils/email-validator", function(){ return i("mon-pix/utils/email-validator.js");});
d("mon-pix/utils/embed-allowed-origins", function(){ return i("mon-pix/utils/embed-allowed-origins.js");});
d("mon-pix/utils/errors-messages", function(){ return i("mon-pix/utils/errors-messages.js");});
d("mon-pix/utils/form-validation", function(){ return i("mon-pix/utils/form-validation.js");});
d("mon-pix/utils/generate-random-string", function(){ return i("mon-pix/utils/generate-random-string.js");});
d("mon-pix/utils/get-challenge-type", function(){ return i("mon-pix/utils/get-challenge-type.js");});
d("mon-pix/utils/labeled-checkboxes", function(){ return i("mon-pix/utils/labeled-checkboxes.js");});
d("mon-pix/utils/labels-as-object", function(){ return i("mon-pix/utils/labels-as-object.js");});
d("mon-pix/utils/parse-iso-date-only", function(){ return i("mon-pix/utils/parse-iso-date-only.js");});
d("mon-pix/utils/password-validator", function(){ return i("mon-pix/utils/password-validator.js");});
d("mon-pix/utils/pix-window", function(){ return i("mon-pix/utils/pix-window.js");});
d("mon-pix/utils/progress-in-assessment", function(){ return i("mon-pix/utils/progress-in-assessment.js");});
d("mon-pix/utils/proposals-as-array", function(){ return i("mon-pix/utils/proposals-as-array.js");});
d("mon-pix/utils/proposals-as-blocks", function(){ return i("mon-pix/utils/proposals-as-blocks.js");});
d("mon-pix/utils/proposals-parser/challenge-response-template", function(){ return i("mon-pix/utils/proposals-parser/challenge-response-template.js");});
d("mon-pix/utils/proposals-parser/input-block", function(){ return i("mon-pix/utils/proposals-parser/input-block.js");});
d("mon-pix/utils/proposals-parser/response-block", function(){ return i("mon-pix/utils/proposals-parser/response-block.js");});
d("mon-pix/utils/proposals-parser/select-block", function(){ return i("mon-pix/utils/proposals-parser/select-block.js");});
d("mon-pix/utils/proposals-parser/splitters", function(){ return i("mon-pix/utils/proposals-parser/splitters.js");});
d("mon-pix/utils/proposals-parser/text-block", function(){ return i("mon-pix/utils/proposals-parser/text-block.js");});
d("mon-pix/utils/pshuffle", function(){ return i("mon-pix/utils/pshuffle.js");});
d("mon-pix/utils/result-details-as-object", function(){ return i("mon-pix/utils/result-details-as-object.js");});
d("mon-pix/utils/result-icon", function(){ return i("mon-pix/utils/result-icon.js");});
d("mon-pix/utils/session-storage-entry", function(){ return i("mon-pix/utils/session-storage-entry.js");});
d("mon-pix/utils/solution-as-object", function(){ return i("mon-pix/utils/solution-as-object.js");});
d("mon-pix/utils/standardize-number", function(){ return i("mon-pix/utils/standardize-number.js");});
d("mon-pix/utils/value-as-array-of-boolean", function(){ return i("mon-pix/utils/value-as-array-of-boolean.js");});
d("mon-pix/services/metrics", function(){ return i("mon-pix/services/metrics.js");});
d("mon-pix/services/cookies", function(){ return i("mon-pix/services/cookies.js");});
d("mon-pix/data-adapter", function(){ return i("mon-pix/data-adapter.js");});
d("mon-pix/initializers/ember-data", function(){ return i("mon-pix/initializers/ember-data.js");});
d("mon-pix/services/dayjs", function(){ return i("mon-pix/services/dayjs.js");});
d("mon-pix/services/keyboard", function(){ return i("mon-pix/services/keyboard.js");});
d("mon-pix/services/page-title", function(){ return i("mon-pix/services/page-title.js");});
d("mon-pix/initializers/ember-simple-auth", function(){ return i("mon-pix/initializers/ember-simple-auth.js");});
d("mon-pix/session-stores/application", function(){ return i("mon-pix/session-stores/application.js");});
d("mon-pix/utils/inject", function(){ return i("mon-pix/utils/inject.js");});
d("mon-pix/utils/is-fastboot", function(){ return i("mon-pix/utils/is-fastboot.js");});
d("mon-pix/utils/location", function(){ return i("mon-pix/utils/location.js");});
d("mon-pix/utils/objects-are-equal", function(){ return i("mon-pix/utils/objects-are-equal.js");});
d("mon-pix/services/element-helper", function(){ return i("mon-pix/services/element-helper.js");});
d("mon-pix/services/pix-toast", function(){ return i("mon-pix/services/pix-toast.js");});
d("mon-pix/services/-ensure-registered", function(){ return i("mon-pix/services/-ensure-registered.js");});
d("mon-pix/component-managers/glimmer", function(){ return i("mon-pix/component-managers/glimmer.js");});
d("mon-pix/instance-initializers/sentry-performance", function(){ return i("mon-pix/instance-initializers/sentry-performance.js");});
d("mon-pix/initializers/app-version", function(){ return i("mon-pix/initializers/app-version.js");});
d("mon-pix/initializers/component-styles", function(){ return i("mon-pix/initializers/component-styles.js");});
d("mon-pix/instance-initializers/route-styles", function(){ return i("mon-pix/instance-initializers/route-styles.js");});
d("mon-pix/mixins/style-namespacing-extras", function(){ return i("mon-pix/mixins/style-namespacing-extras.js");});
d("mon-pix/services/intl", function(){ return i("mon-pix/services/intl.js");});
d("mon-pix/initializers/ember-responsive-breakpoints", function(){ return i("mon-pix/initializers/ember-responsive-breakpoints.js");});
d("mon-pix/services/media", function(){ return i("mon-pix/services/media.js");});
d("mon-pix/templates/account-recovery/find-sco-record", function(){ return i("mon-pix/templates/account-recovery/find-sco-record.hbs");});
d("mon-pix/controllers/account-recovery/find-sco-record", function(){ return i("mon-pix/controllers/account-recovery/find-sco-record.js");});
d("mon-pix/routes/account-recovery/find-sco-record", function(){ return i("mon-pix/routes/account-recovery/find-sco-record.js");});
d("mon-pix/templates/account-recovery/update-sco-record", function(){ return i("mon-pix/templates/account-recovery/update-sco-record.hbs");});
d("mon-pix/controllers/account-recovery/update-sco-record", function(){ return i("mon-pix/controllers/account-recovery/update-sco-record.js");});
d("mon-pix/routes/account-recovery/update-sco-record", function(){ return i("mon-pix/routes/account-recovery/update-sco-record.js");});
d("mon-pix/templates/application", function(){ return i("mon-pix/templates/application.hbs");});
d("mon-pix/controllers/application", function(){ return i("mon-pix/controllers/application.js");});
d("mon-pix/routes/application", function(){ return i("mon-pix/routes/application.js");});
d("mon-pix/templates/assessments", function(){ return i("mon-pix/templates/assessments.hbs");});
d("mon-pix/routes/assessments", function(){ return i("mon-pix/routes/assessments.js");});
d("mon-pix/templates/assessments/challenge", function(){ return i("mon-pix/templates/assessments/challenge.hbs");});
d("mon-pix/controllers/assessments/challenge", function(){ return i("mon-pix/controllers/assessments/challenge.js");});
d("mon-pix/routes/assessments/challenge", function(){ return i("mon-pix/routes/assessments/challenge.js");});
d("mon-pix/templates/assessments/checkpoint", function(){ return i("mon-pix/templates/assessments/checkpoint.hbs");});
d("mon-pix/controllers/assessments/checkpoint", function(){ return i("mon-pix/controllers/assessments/checkpoint.js");});
d("mon-pix/routes/assessments/checkpoint", function(){ return i("mon-pix/routes/assessments/checkpoint.js");});
d("mon-pix/templates/assessments/results", function(){ return i("mon-pix/templates/assessments/results.hbs");});
d("mon-pix/controllers/assessments/results", function(){ return i("mon-pix/controllers/assessments/results.js");});
d("mon-pix/routes/assessments/results", function(){ return i("mon-pix/routes/assessments/results.js");});
d("mon-pix/templates/assessments/resume", function(){ return i("mon-pix/templates/assessments/resume.hbs");});
d("mon-pix/routes/assessments/resume", function(){ return i("mon-pix/routes/assessments/resume.js");});
d("mon-pix/templates/assessments/loading", function(){ return i("mon-pix/templates/assessments/loading.hbs");});
d("mon-pix/templates/assessments/results-loading", function(){ return i("mon-pix/templates/assessments/results-loading.hbs");});
d("mon-pix/templates/authenticated", function(){ return i("mon-pix/templates/authenticated.hbs");});
d("mon-pix/routes/authenticated", function(){ return i("mon-pix/routes/authenticated.js");});
d("mon-pix/templates/authenticated/certifications/join", function(){ return i("mon-pix/templates/authenticated/certifications/join.hbs");});
d("mon-pix/controllers/authenticated/certifications/join", function(){ return i("mon-pix/controllers/authenticated/certifications/join.js");});
d("mon-pix/routes/authenticated/certifications/join", function(){ return i("mon-pix/routes/authenticated/certifications/join.js");});
d("mon-pix/templates/authenticated/certifications/results", function(){ return i("mon-pix/templates/authenticated/certifications/results.hbs");});
d("mon-pix/controllers/authenticated/certifications/results", function(){ return i("mon-pix/controllers/authenticated/certifications/results.js");});
d("mon-pix/routes/authenticated/certifications/results", function(){ return i("mon-pix/routes/authenticated/certifications/results.js");});
d("mon-pix/templates/authenticated/certifications/information", function(){ return i("mon-pix/templates/authenticated/certifications/information.hbs");});
d("mon-pix/routes/authenticated/certifications/information", function(){ return i("mon-pix/routes/authenticated/certifications/information.js");});
d("mon-pix/templates/authenticated/certifications/resume", function(){ return i("mon-pix/templates/authenticated/certifications/resume.hbs");});
d("mon-pix/routes/authenticated/certifications/resume", function(){ return i("mon-pix/routes/authenticated/certifications/resume.js");});
d("mon-pix/templates/authenticated/certifications/start", function(){ return i("mon-pix/templates/authenticated/certifications/start.hbs");});
d("mon-pix/routes/authenticated/certifications/start", function(){ return i("mon-pix/routes/authenticated/certifications/start.js");});
d("mon-pix/templates/authenticated/certifications/start-loading", function(){ return i("mon-pix/templates/authenticated/certifications/start-loading.hbs");});
d("mon-pix/templates/authenticated/user-account", function(){ return i("mon-pix/templates/authenticated/user-account.hbs");});
d("mon-pix/controllers/authenticated/user-account", function(){ return i("mon-pix/controllers/authenticated/user-account.js");});
d("mon-pix/routes/authenticated/user-account", function(){ return i("mon-pix/routes/authenticated/user-account.js");});
d("mon-pix/templates/authenticated/user-account/connection-methods", function(){ return i("mon-pix/templates/authenticated/user-account/connection-methods.hbs");});
d("mon-pix/controllers/authenticated/user-account/connection-methods", function(){ return i("mon-pix/controllers/authenticated/user-account/connection-methods.js");});
d("mon-pix/routes/authenticated/user-account/connection-methods", function(){ return i("mon-pix/routes/authenticated/user-account/connection-methods.js");});
d("mon-pix/templates/authenticated/user-account/language", function(){ return i("mon-pix/templates/authenticated/user-account/language.hbs");});
d("mon-pix/controllers/authenticated/user-account/language", function(){ return i("mon-pix/controllers/authenticated/user-account/language.js");});
d("mon-pix/routes/authenticated/user-account/language", function(){ return i("mon-pix/routes/authenticated/user-account/language.js");});
d("mon-pix/templates/authenticated/user-account/delete-account", function(){ return i("mon-pix/templates/authenticated/user-account/delete-account.hbs");});
d("mon-pix/routes/authenticated/user-account/delete-account", function(){ return i("mon-pix/routes/authenticated/user-account/delete-account.js");});
d("mon-pix/routes/authenticated/user-account/index", function(){ return i("mon-pix/routes/authenticated/user-account/index.js");});
d("mon-pix/templates/authenticated/user-account/personal-information", function(){ return i("mon-pix/templates/authenticated/user-account/personal-information.hbs");});
d("mon-pix/routes/authenticated/user-account/personal-information", function(){ return i("mon-pix/routes/authenticated/user-account/personal-information.js");});
d("mon-pix/templates/authenticated/user-certifications", function(){ return i("mon-pix/templates/authenticated/user-certifications.hbs");});
d("mon-pix/templates/authenticated/user-certifications/get", function(){ return i("mon-pix/templates/authenticated/user-certifications/get.hbs");});
d("mon-pix/controllers/authenticated/user-certifications/get", function(){ return i("mon-pix/controllers/authenticated/user-certifications/get.js");});
d("mon-pix/routes/authenticated/user-certifications/get", function(){ return i("mon-pix/routes/authenticated/user-certifications/get.js");});
d("mon-pix/templates/authenticated/user-certifications/index", function(){ return i("mon-pix/templates/authenticated/user-certifications/index.hbs");});
d("mon-pix/routes/authenticated/user-certifications/index", function(){ return i("mon-pix/routes/authenticated/user-certifications/index.js");});
d("mon-pix/templates/authenticated/user-tests", function(){ return i("mon-pix/templates/authenticated/user-tests.hbs");});
d("mon-pix/controllers/authenticated/user-tests", function(){ return i("mon-pix/controllers/authenticated/user-tests.js");});
d("mon-pix/routes/authenticated/user-tests", function(){ return i("mon-pix/routes/authenticated/user-tests.js");});
d("mon-pix/templates/authenticated/user-trainings", function(){ return i("mon-pix/templates/authenticated/user-trainings.hbs");});
d("mon-pix/controllers/authenticated/user-trainings", function(){ return i("mon-pix/controllers/authenticated/user-trainings.js");});
d("mon-pix/routes/authenticated/user-trainings", function(){ return i("mon-pix/routes/authenticated/user-trainings.js");});
d("mon-pix/templates/authenticated/user-tutorials", function(){ return i("mon-pix/templates/authenticated/user-tutorials.hbs");});
d("mon-pix/routes/authenticated/user-tutorials", function(){ return i("mon-pix/routes/authenticated/user-tutorials.js");});
d("mon-pix/templates/authenticated/user-tutorials/recommended", function(){ return i("mon-pix/templates/authenticated/user-tutorials/recommended.hbs");});
d("mon-pix/controllers/authenticated/user-tutorials/recommended", function(){ return i("mon-pix/controllers/authenticated/user-tutorials/recommended.js");});
d("mon-pix/routes/authenticated/user-tutorials/recommended", function(){ return i("mon-pix/routes/authenticated/user-tutorials/recommended.js");});
d("mon-pix/templates/authenticated/user-tutorials/saved", function(){ return i("mon-pix/templates/authenticated/user-tutorials/saved.hbs");});
d("mon-pix/controllers/authenticated/user-tutorials/saved", function(){ return i("mon-pix/controllers/authenticated/user-tutorials/saved.js");});
d("mon-pix/routes/authenticated/user-tutorials/saved", function(){ return i("mon-pix/routes/authenticated/user-tutorials/saved.js");});
d("mon-pix/routes/authenticated/user-tutorials/index", function(){ return i("mon-pix/routes/authenticated/user-tutorials/index.js");});
d("mon-pix/templates/authenticated/competences", function(){ return i("mon-pix/templates/authenticated/competences.hbs");});
d("mon-pix/routes/authenticated/competences", function(){ return i("mon-pix/routes/authenticated/competences.js");});
d("mon-pix/templates/authenticated/competences/details", function(){ return i("mon-pix/templates/authenticated/competences/details.hbs");});
d("mon-pix/routes/authenticated/competences/details", function(){ return i("mon-pix/routes/authenticated/competences/details.js");});
d("mon-pix/templates/authenticated/competences/results", function(){ return i("mon-pix/templates/authenticated/competences/results.hbs");});
d("mon-pix/routes/authenticated/competences/results", function(){ return i("mon-pix/routes/authenticated/competences/results.js");});
d("mon-pix/routes/authenticated/competences/resume", function(){ return i("mon-pix/routes/authenticated/competences/resume.js");});
d("mon-pix/routes/authenticated/index", function(){ return i("mon-pix/routes/authenticated/index.js");});
d("mon-pix/templates/authenticated/profile", function(){ return i("mon-pix/templates/authenticated/profile.hbs");});
d("mon-pix/routes/authenticated/profile", function(){ return i("mon-pix/routes/authenticated/profile.js");});
d("mon-pix/templates/authenticated/sitemap", function(){ return i("mon-pix/templates/authenticated/sitemap.hbs");});
d("mon-pix/routes/authenticated/sitemap", function(){ return i("mon-pix/routes/authenticated/sitemap.js");});
d("mon-pix/templates/authenticated/user-dashboard", function(){ return i("mon-pix/templates/authenticated/user-dashboard.hbs");});
d("mon-pix/routes/authenticated/user-dashboard", function(){ return i("mon-pix/routes/authenticated/user-dashboard.js");});
d("mon-pix/templates/authentication/login-or-register-oidc", function(){ return i("mon-pix/templates/authentication/login-or-register-oidc.hbs");});
d("mon-pix/controllers/authentication/login-or-register-oidc", function(){ return i("mon-pix/controllers/authentication/login-or-register-oidc.js");});
d("mon-pix/routes/authentication/login-or-register-oidc", function(){ return i("mon-pix/routes/authentication/login-or-register-oidc.js");});
d("mon-pix/templates/authentication/sso-selection", function(){ return i("mon-pix/templates/authentication/sso-selection.hbs");});
d("mon-pix/controllers/authentication/sso-selection", function(){ return i("mon-pix/controllers/authentication/sso-selection.js");});
d("mon-pix/routes/authentication/sso-selection", function(){ return i("mon-pix/routes/authentication/sso-selection.js");});
d("mon-pix/routes/authentication/login-gar", function(){ return i("mon-pix/routes/authentication/login-gar.js");});
d("mon-pix/routes/authentication/login-oidc", function(){ return i("mon-pix/routes/authentication/login-oidc.js");});
d("mon-pix/templates/authentication/login", function(){ return i("mon-pix/templates/authentication/login.hbs");});
d("mon-pix/routes/authentication/login", function(){ return i("mon-pix/routes/authentication/login.js");});
d("mon-pix/templates/campaigns", function(){ return i("mon-pix/templates/campaigns.hbs");});
d("mon-pix/routes/campaigns", function(){ return i("mon-pix/routes/campaigns.js");});
d("mon-pix/templates/campaigns/campaign-landing-page", function(){ return i("mon-pix/templates/campaigns/campaign-landing-page.hbs");});
d("mon-pix/controllers/campaigns/campaign-landing-page", function(){ return i("mon-pix/controllers/campaigns/campaign-landing-page.js");});
d("mon-pix/routes/campaigns/campaign-landing-page", function(){ return i("mon-pix/routes/campaigns/campaign-landing-page.js");});
d("mon-pix/routes/campaigns/invited", function(){ return i("mon-pix/routes/campaigns/invited.js");});
d("mon-pix/templates/campaigns/invited/fill-in-participant-external-id", function(){ return i("mon-pix/templates/campaigns/invited/fill-in-participant-external-id.hbs");});
d("mon-pix/controllers/campaigns/invited/fill-in-participant-external-id", function(){ return i("mon-pix/controllers/campaigns/invited/fill-in-participant-external-id.js");});
d("mon-pix/routes/campaigns/invited/fill-in-participant-external-id", function(){ return i("mon-pix/routes/campaigns/invited/fill-in-participant-external-id.js");});
d("mon-pix/templates/campaigns/invited/student-sco", function(){ return i("mon-pix/templates/campaigns/invited/student-sco.hbs");});
d("mon-pix/controllers/campaigns/invited/student-sco", function(){ return i("mon-pix/controllers/campaigns/invited/student-sco.js");});
d("mon-pix/routes/campaigns/invited/student-sco", function(){ return i("mon-pix/routes/campaigns/invited/student-sco.js");});
d("mon-pix/templates/campaigns/invited/reconciliation", function(){ return i("mon-pix/templates/campaigns/invited/reconciliation.hbs");});
d("mon-pix/routes/campaigns/invited/reconciliation", function(){ return i("mon-pix/routes/campaigns/invited/reconciliation.js");});
d("mon-pix/templates/campaigns/invited/student-sup", function(){ return i("mon-pix/templates/campaigns/invited/student-sup.hbs");});
d("mon-pix/routes/campaigns/invited/student-sup", function(){ return i("mon-pix/routes/campaigns/invited/student-sup.js");});
d("mon-pix/templates/campaigns/invited/loading", function(){ return i("mon-pix/templates/campaigns/invited/loading.hbs");});
d("mon-pix/routes/campaigns/join", function(){ return i("mon-pix/routes/campaigns/join.js");});
d("mon-pix/templates/campaigns/join/sco-mediacentre", function(){ return i("mon-pix/templates/campaigns/join/sco-mediacentre.hbs");});
d("mon-pix/controllers/campaigns/join/sco-mediacentre", function(){ return i("mon-pix/controllers/campaigns/join/sco-mediacentre.js");});
d("mon-pix/routes/campaigns/join/sco-mediacentre", function(){ return i("mon-pix/routes/campaigns/join/sco-mediacentre.js");});
d("mon-pix/templates/campaigns/join/student-sco", function(){ return i("mon-pix/templates/campaigns/join/student-sco.hbs");});
d("mon-pix/controllers/campaigns/join/student-sco", function(){ return i("mon-pix/controllers/campaigns/join/student-sco.js");});
d("mon-pix/routes/campaigns/join/student-sco", function(){ return i("mon-pix/routes/campaigns/join/student-sco.js");});
d("mon-pix/routes/campaigns/join/anonymous", function(){ return i("mon-pix/routes/campaigns/join/anonymous.js");});
d("mon-pix/routes/campaigns/profiles-collection", function(){ return i("mon-pix/routes/campaigns/profiles-collection.js");});
d("mon-pix/templates/campaigns/profiles-collection/profile-already-shared", function(){ return i("mon-pix/templates/campaigns/profiles-collection/profile-already-shared.hbs");});
d("mon-pix/controllers/campaigns/profiles-collection/profile-already-shared", function(){ return i("mon-pix/controllers/campaigns/profiles-collection/profile-already-shared.js");});
d("mon-pix/routes/campaigns/profiles-collection/profile-already-shared", function(){ return i("mon-pix/routes/campaigns/profiles-collection/profile-already-shared.js");});
d("mon-pix/templates/campaigns/profiles-collection/send-profile", function(){ return i("mon-pix/templates/campaigns/profiles-collection/send-profile.hbs");});
d("mon-pix/controllers/campaigns/profiles-collection/send-profile", function(){ return i("mon-pix/controllers/campaigns/profiles-collection/send-profile.js");});
d("mon-pix/routes/campaigns/profiles-collection/send-profile", function(){ return i("mon-pix/routes/campaigns/profiles-collection/send-profile.js");});
d("mon-pix/routes/campaigns/profiles-collection/start-or-resume", function(){ return i("mon-pix/routes/campaigns/profiles-collection/start-or-resume.js");});
d("mon-pix/routes/campaigns/access", function(){ return i("mon-pix/routes/campaigns/access.js");});
d("mon-pix/templates/campaigns/archived-error", function(){ return i("mon-pix/templates/campaigns/archived-error.hbs");});
d("mon-pix/routes/campaigns/archived-error", function(){ return i("mon-pix/routes/campaigns/archived-error.js");});
d("mon-pix/routes/campaigns/assessment", function(){ return i("mon-pix/routes/campaigns/assessment.js");});
d("mon-pix/templates/campaigns/assessment/results", function(){ return i("mon-pix/templates/campaigns/assessment/results.hbs");});
d("mon-pix/routes/campaigns/assessment/results", function(){ return i("mon-pix/routes/campaigns/assessment/results.js");});
d("mon-pix/routes/campaigns/assessment/start-or-resume", function(){ return i("mon-pix/routes/campaigns/assessment/start-or-resume.js");});
d("mon-pix/templates/campaigns/assessment/tutorial", function(){ return i("mon-pix/templates/campaigns/assessment/tutorial.hbs");});
d("mon-pix/routes/campaigns/assessment/tutorial", function(){ return i("mon-pix/routes/campaigns/assessment/tutorial.js");});
d("mon-pix/routes/campaigns/entrance", function(){ return i("mon-pix/routes/campaigns/entrance.js");});
d("mon-pix/routes/campaigns/entry-point", function(){ return i("mon-pix/routes/campaigns/entry-point.js");});
d("mon-pix/templates/campaigns/existing-participation", function(){ return i("mon-pix/templates/campaigns/existing-participation.hbs");});
d("mon-pix/routes/campaigns/existing-participation", function(){ return i("mon-pix/routes/campaigns/existing-participation.js");});
d("mon-pix/routes/campaigns/results-loader", function(){ return i("mon-pix/routes/campaigns/results-loader.js");});
d("mon-pix/templates/campaigns/loading", function(){ return i("mon-pix/templates/campaigns/loading.hbs");});
d("mon-pix/controllers/certification-course", function(){ return i("mon-pix/controllers/certification-course.js");});
d("mon-pix/templates/courses/start-error", function(){ return i("mon-pix/templates/courses/start-error.hbs");});
d("mon-pix/controllers/courses/start-error", function(){ return i("mon-pix/controllers/courses/start-error.js");});
d("mon-pix/routes/courses/start", function(){ return i("mon-pix/routes/courses/start.js");});
d("mon-pix/templates/courses/start-loading", function(){ return i("mon-pix/templates/courses/start-loading.hbs");});
d("mon-pix/templates/fill-in-campaign-code", function(){ return i("mon-pix/templates/fill-in-campaign-code.hbs");});
d("mon-pix/controllers/fill-in-campaign-code", function(){ return i("mon-pix/controllers/fill-in-campaign-code.js");});
d("mon-pix/routes/fill-in-campaign-code", function(){ return i("mon-pix/routes/fill-in-campaign-code.js");});
d("mon-pix/templates/fill-in-certificate-verification-code", function(){ return i("mon-pix/templates/fill-in-certificate-verification-code.hbs");});
d("mon-pix/controllers/fill-in-certificate-verification-code", function(){ return i("mon-pix/controllers/fill-in-certificate-verification-code.js");});
d("mon-pix/routes/fill-in-certificate-verification-code", function(){ return i("mon-pix/routes/fill-in-certificate-verification-code.js");});
d("mon-pix/templates/shared-certification", function(){ return i("mon-pix/templates/shared-certification.hbs");});
d("mon-pix/controllers/shared-certification", function(){ return i("mon-pix/controllers/shared-certification.js");});
d("mon-pix/routes/shared-certification", function(){ return i("mon-pix/routes/shared-certification.js");});
d("mon-pix/controllers/terms-of-service-oidc", function(){ return i("mon-pix/controllers/terms-of-service-oidc.js");});
d("mon-pix/routes/terms-of-service-oidc", function(){ return i("mon-pix/routes/terms-of-service-oidc.js");});
d("mon-pix/templates/terms-of-service", function(){ return i("mon-pix/templates/terms-of-service.hbs");});
d("mon-pix/controllers/terms-of-service", function(){ return i("mon-pix/controllers/terms-of-service.js");});
d("mon-pix/routes/terms-of-service", function(){ return i("mon-pix/routes/terms-of-service.js");});
d("mon-pix/controllers/user-tutorials", function(){ return i("mon-pix/controllers/user-tutorials.js");});
d("mon-pix/routes/challenge-preview", function(){ return i("mon-pix/routes/challenge-preview.js");});
d("mon-pix/templates/companion", function(){ return i("mon-pix/templates/companion.hbs");});
d("mon-pix/routes/companion", function(){ return i("mon-pix/routes/companion.js");});
d("mon-pix/templates/download-session-results", function(){ return i("mon-pix/templates/download-session-results.hbs");});
d("mon-pix/routes/download-session-results", function(){ return i("mon-pix/routes/download-session-results.js");});
d("mon-pix/templates/error", function(){ return i("mon-pix/templates/error.hbs");});
d("mon-pix/routes/error", function(){ return i("mon-pix/routes/error.js");});
d("mon-pix/templates/inscription/inscription", function(){ return i("mon-pix/templates/inscription/inscription.hbs");});
d("mon-pix/routes/inscription/inscription", function(){ return i("mon-pix/routes/inscription/inscription.js");});
d("mon-pix/routes/inscription/sso-selection", function(){ return i("mon-pix/routes/inscription/sso-selection.js");});
d("mon-pix/templates/logout", function(){ return i("mon-pix/templates/logout.hbs");});
d("mon-pix/routes/logout", function(){ return i("mon-pix/routes/logout.js");});
d("mon-pix/routes/module", function(){ return i("mon-pix/routes/module.js");});
d("mon-pix/templates/module/details", function(){ return i("mon-pix/templates/module/details.hbs");});
d("mon-pix/routes/module/details", function(){ return i("mon-pix/routes/module/details.js");});
d("mon-pix/routes/module/index", function(){ return i("mon-pix/routes/module/index.js");});
d("mon-pix/templates/module/passage", function(){ return i("mon-pix/templates/module/passage.hbs");});
d("mon-pix/routes/module/passage", function(){ return i("mon-pix/routes/module/passage.js");});
d("mon-pix/templates/module/recap", function(){ return i("mon-pix/templates/module/recap.hbs");});
d("mon-pix/routes/module/recap", function(){ return i("mon-pix/routes/module/recap.js");});
d("mon-pix/templates/not-connected", function(){ return i("mon-pix/templates/not-connected.hbs");});
d("mon-pix/routes/not-connected", function(){ return i("mon-pix/routes/not-connected.js");});
d("mon-pix/routes/not-found", function(){ return i("mon-pix/routes/not-found.js");});
d("mon-pix/templates/password-reset-demand", function(){ return i("mon-pix/templates/password-reset-demand.hbs");});
d("mon-pix/routes/password-reset-demand", function(){ return i("mon-pix/routes/password-reset-demand.js");});
d("mon-pix/templates/reset-password", function(){ return i("mon-pix/templates/reset-password.hbs");});
d("mon-pix/routes/reset-password", function(){ return i("mon-pix/routes/reset-password.js");});
d("mon-pix/templates/update-expired-password", function(){ return i("mon-pix/templates/update-expired-password.hbs");});
d("mon-pix/routes/update-expired-password", function(){ return i("mon-pix/routes/update-expired-password.js");});
d("mon-pix/templates/campaigns-error", function(){ return i("mon-pix/templates/campaigns-error.hbs");});
d("mon-pix/templates/loading", function(){ return i("mon-pix/templates/loading.hbs");});
d("mon-pix/templates/module-preview", function(){ return i("mon-pix/templates/module-preview.hbs");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("mon-pix/instance-initializers/setup-fetch", function(){ return i("mon-pix/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"API_HOST":"","FT_FOCUS_CHALLENGE_ENABLED":true,"isTimerCountdownEnabled":true,"LOAD_EXTERNAL_SCRIPT":true,"NUMBER_OF_CHALLENGES_BETWEEN_TWO_CHECKPOINTS":5,"MAX_CONCURRENT_AJAX_CALLS":8,"MILLISECONDS_BEFORE_MAIL_RESEND":7000,"BANNER_CONTENT":"Cette plateforme est destinée aux tests internes à Pix. Vos pix ne sont pas comptabilisés <br><a href=https://app.pix.fr>Allez sur le site Pix.</a>","BANNER_TYPE":"warning","IS_PROD_ENVIRONMENT":false,"EMBED_ALLOWED_ORIGINS":["https://epreuves.pix.fr","https://1024pix.github.io","https://epreuves-pr*.review.pix.fr"],"API_ERROR_MESSAGES":{"BAD_REQUEST":{"CODE":"400","I18N_KEY":"common.api-error-messages.bad-request-error"},"LOGIN_UNAUTHORIZED":{"CODE":"401","I18N_KEY":"common.api-error-messages.login-unauthorized-error"},"USER_IS_TEMPORARY_BLOCKED":{"CODE":"403","I18N_KEY":"common.api-error-messages.login-user-temporary-blocked-error"},"USER_IS_BLOCKED":{"CODE":"403","I18N_KEY":"common.api-error-messages.login-user-blocked-error"},"INTERNAL_SERVER_ERROR":{"CODE":"500","I18N_KEY":"common.api-error-messages.internal-server-error"},"BAD_GATEWAY":{"CODE":"502","I18N_KEY":"common.api-error-messages.internal-server-error"},"GATEWAY_TIMEOUT":{"CODE":"504","I18N_KEY":"common.api-error-messages.gateway-timeout-error"}},"AUTHENTICATED_SOURCE_FROM_GAR":"external","NUMBER_OF_CHALLENGES_FOR_FLASH_METHOD":24,"COOKIE_LOCALE_LIFESPAN_IN_SECONDS":31536000,"AUTONOMOUS_COURSES_ORGANIZATION_ID":900000000,"APP_VERSION":"dev","AUTHENTICATION":{"SCOPE":"mon-pix"},"name":"mon-pix","version":"5.2.0"});
}

