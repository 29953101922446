import { template as template_db5ef1965a274aa0a2bc4e718f9033c6 } from "@ember/template-compiler";
import Footer from '../footer';
import NavbarHeader from '../navbar-header';
export default template_db5ef1965a274aa0a2bc4e718f9033c6(`
  <NavbarHeader />
  {{yield}}
  <Footer />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
