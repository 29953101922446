import { template as template_be8c900b89254fb7ad3207b0be91d485 } from "@ember/template-compiler";
export default template_be8c900b89254fb7ad3207b0be91d485(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
