import { template as template_ee81c3e1e7ba4e498a755a30ebfb0a52 } from "@ember/template-compiler";
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { t } from 'ember-intl';
export default template_ee81c3e1e7ba4e498a755a30ebfb0a52(`
  <PixNotificationAlert @type="communication" @withIcon="true" role="alert">
    {{t "pages.modulix.beta-banner"}}
  </PixNotificationAlert>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
